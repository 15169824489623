import React, { useState } from "react";
import PropTypes from "prop-types";
import { Marker } from "@react-google-maps/api";
import { useTheme } from "@material-ui/core/styles";
//import { SymbolPath, Point } from "google.maps";

function SampleMarker(props) {
  const { position, selected, id, number, onClick } = props;
  const theme = useTheme();

  const [hovered, setHovered] = useState(false);

  const label = `${number}`;
  const fillColor = selected
    ? theme.palette.primary.light
    : hovered
    ? theme.palette.primary.dark
    : theme.palette.primary.main;

  const scale = selected ? 20 : 15;

  const icon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: fillColor,
    fillOpacity: 1,
    strokeWeight: 0,
    scale: scale,
    anchor: new window.google.maps.Point(0, 0),
  };

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  const handleClick = () => {
    onClick(id);
  };

  return (
    <Marker
      position={position}
      label={label}
      icon={icon}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    />
  );
}

SampleMarker.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  selected: PropTypes.bool,
  id: PropTypes.string,
  number: PropTypes.number,
  onClick: PropTypes.func,
};

export default SampleMarker;
