export function getDatabaseField(legalDocType) {
  switch (legalDocType) {
    case "privacyPolicy":
      return "acceptedLegalDocs.privacyPolicy";
    case "termsOfUse":
      return "acceptedLegalDocs.termsOfUse";
  }
}

export function getUrl(legalDocType) {
  switch (legalDocType) {
    case "privacyPolicy":
      return "/privacy-policy.md";
    case "termsOfUse":
      return "/terms-of-use.md";
  }
}

export function getDialogTitle(legalDocType) {
  switch (legalDocType) {
    case "privacyPolicy":
      return "Please Accept the Privacy Policy";
    case "termsOfUse":
      return "Please Accept the Terms of Use";
  }
}
