import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import "firebase/compat/storage";

async function createReportRequest(inspectionId, reportType) {
  const createReportFn = firebase.functions().httpsCallable("createReport");

  const result = await createReportFn({
    inspectionId: inspectionId,
    reportType: reportType,
  });

  return result.data.data;
}

function listenToRequestStatus(requestPath, onChange, onError) {
  return firebase
    .firestore()
    .doc(requestPath)
    .onSnapshot((doc) => {
      const data = doc.data();

      if (data.status == "error") {
        onError(data.error);
      }

      onChange(doc.data().status, requestPath);
    }, onError);
}

async function getDownloadURL(requestPath) {
  const snap = await firebase.firestore().doc(requestPath).get();

  if (!snap.exists) {
    throw new Error("Report request does not exist.");
  }

  const data = snap.data();

  if (!data.status == "done") {
    throw new Error("Report is not finished generating.");
  }

  if (!data.storagePath) {
    throw new Error("Request does not have a storage path.");
  }

  return await firebase
    .storage()
    .ref()
    .child(data.storagePath)
    .getDownloadURL();
}

export { createReportRequest, listenToRequestStatus, getDownloadURL };