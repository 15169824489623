import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../hooks";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getUser, putUser } from "../../data/user-store";
import { OrganizationSelector } from "../../widgets";
import { Row, Column } from "../../widgets";
import ControlledSnackbar from "../../widgets/ControlledSnackbar";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  formContent: {
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function getValidationErrors(formState, existingUser) {
  let errors = {};

  if (!formState.email) {
    errors.email = "Email is required";
  }

  if (!formState.password && !existingUser) {
    errors.password = "Password is required";
  }

  if (formState.password != formState.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }

  if (!formState.organization?.id) {
    errors.organization = "Organization is required";
  }

  return errors;
}

function mapUserToFormState(user) {
  if (!user) return initialState;

  return {
    email: user.email,
    password: user.password,
    organization: {
      name: user.organization,
      id: user.claims.organization,
    },
    givenName: user.givenName,
    familyName: user.familyName,
    isAdmin: user.claims.isAdmin,
    isSuperAdmin: user.claims.isSuperAdmin,
  };
}

const initialState = {
  email: "",
  password: "",
  confirmPassword: "",
  givenName: "",
  familyName: "",
  orgId: "",
  organization: {
    id: "",
    name: "",
  },
  isAdmin: false,
  isSuperAdmin: false,
};

function EditUserForm(props) {
  const { userId, onCancel, onSave } = props;
  const classes = useStyles();

  const [auth] = useAuth();

  const [state, setState] = useState(initialState);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbarState, setSnackbarState] = useState({});

  useEffect(async () => {
    if (open && userId) {
      try {
        const user = await getUser(userId);

        setState(mapUserToFormState(user));
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error.message,
        });
      }
    }
  }, [open, userId]);

  const getTitle = () => {
    if (userId) {
      return "Edit User";
    } else {
      return "Add User";
    }
  };

  const handleClose = () => {
    onCancel();
  };

  const handleChange = (event) => {
    const name = event.target.id;

    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleOrganizationChange = (organization) => {
    setState({
      ...state,
      organization: organization,
    });
  };

  const handleCheckChange = (event) => {
    const name = event.target.name;

    setState({
      ...state,
      [name]: event.target.checked,
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleSave = async () => {
    const validationErrors = getValidationErrors(state, userId);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setSaving(true);

    try {
      const result = await putUser(userId, state);

      setSaving(false);
      onSave({
        open: true,
        message: result.message,
        severity: "success",
        autoHideDuration: 6000,
      });
    } catch (error) {
      setSaving(false);
      setSnackbarState({
        open: true,
        message: error.message,
      });
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Backdrop className={classes.backdrop} open={saving}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle id="form-dialog-title">{getTitle()}</DialogTitle>
      <DialogContent className={classes.formContent}>
        <Column spacing={2}>
          <TextField
            autoFocus
            className={classes.formControl}
            margin="dense"
            id="givenName"
            label="First Name"
            type="text"
            variant="filled"
            value={state.givenName}
            onChange={handleChange}
            error={errors.givenName}
            helperText={errors.givenName}
          />
          <TextField
            className={classes.formControl}
            margin="dense"
            id="familyName"
            label="Last Name"
            type="text"
            variant="filled"
            value={state.familyName}
            onChange={handleChange}
            error={errors.familyName}
            helperText={errors.familyName}
          />
          <TextField
            className={classes.formControl}
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            variant="filled"
            value={state.email}
            onChange={handleChange}
            required
            error={errors.email}
            helperText={errors.email}
            autoComplete="off"
          />
          <TextField
            className={classes.formControl}
            margin="dense"
            id="password"
            label="Password"
            type="password"
            autoComplete="new-password"
            variant="filled"
            value={state.password}
            onChange={handleChange}
            required
            error={errors.password}
            helperText={errors.password}
          />
          <TextField
            className={classes.formControl}
            margin="dense"
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            autoComplete="new-password"
            variant="filled"
            value={state.confirmPassword}
            onChange={handleChange}
            required
            error={errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
          <Row spacing={2}>
            {auth.user.claims.isSuperAdmin && (
              <OrganizationSelector
                organization={state.organization}
                onChange={handleOrganizationChange}
                error={errors.organization}
                variant="filled"
              />
            )}
            {auth.user.claims.isAdmin && (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Roles</FormLabel>
                <FormGroup>
                  {auth.user.claims.isSuperAdmin && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.isSuperAdmin}
                          onChange={handleCheckChange}
                          name="isSuperAdmin"
                        />
                      }
                      label="Super Admin"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.isAdmin}
                        onChange={handleCheckChange}
                        name="isAdmin"
                      />
                    }
                    label="Admin"
                  />
                </FormGroup>
              </FormControl>
            )}
          </Row>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={saving}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={saving} color="primary">
          Save
        </Button>
      </DialogActions>

      <ControlledSnackbar state={snackbarState} onClose={handleSnackbarClose} />
    </Dialog>
  );
}

EditUserForm.propTypes = {
  userId: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default EditUserForm;
