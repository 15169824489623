import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export async function getOrganization(id) {
  const snap = await firebase.firestore().doc(`organizations/${id}`).get();
  if (!snap) return null;

  return snap.data();
}

export async function putOrganization(org, targetId) {
  console.log(org);
  const ref = targetId
    ? firebase.firestore().doc(`organizations/${targetId}`)
    : firebase.firestore().collection("organizations").doc();

  await ref.set(org);

  return { message: "Organization successfully saved." };
}

export async function hasAvailableSeats(org) {
  const usersSnap = await firebase
    .firestore()
    .collection("users")
    .where("claims.organization", "==", org.id)
    .where("disabled", "==", false)
    .get();

  const orgSnap = await firebase
    .firestore()
    .doc(`organizations/${org.id}`)
    .get();

  const orgData = orgSnap.data();

  return usersSnap.size < orgData.subscription.seats;
}

export async function updateSubscription(orgId, product, quantity) {
  const updateSubscriptionFn = firebase
    .functions()
    .httpsCallable("updateSubscription");

  const result = await updateSubscriptionFn({
    orgId: orgId,
    selectedProduct: {
      price: product,
      quantity: quantity,
    },
  });

  return result.data.message;
}
