import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CustomerForm from "./CustomerForm";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { signUp } from "../../data/user-store";
import ControlledSnackbar from "../../widgets/ControlledSnackbar";
import SubscriptionTierCard from "../../widgets/SubscriptionTierCard";
import Typography from "@material-ui/core/Typography";
import { Row, Column } from "../../widgets";

const useStyles = makeStyles(() => ({
  root: {
    width: "70%",
    minWidth: 600,
    maxWidth: 1000,
    marginTop: "auto",
    marginBottom: "auto",
  },
  contentCard: {
    flexGrow: 2,
  },
}));

export default function CreateAccount() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const product = query.get("product");
  const initialQuantity = query.get("quantity");

  const [snackbarState, setSnackbarState] = useState({});
  const [saving, setSaving] = useState(false);
  const [quantity, setQuantity] = useState(initialQuantity);

  const handleSubmit = async (formData) => {
    setSaving(true);

    try {
      await signUp(formData, {
        price: product,
        quantity: quantity,
      });

      history.replace(`/sign-up/waiting-for-verification`);
    } catch (error) {
      setSnackbarState({
        open: true,
        message:
          "We encountered a problem processing your request. It may be that the email or company name you provided is already in use. If the problem persists, please contact Runyon support.",
        severity: "warning",
        error: error,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleQuantityChanged = (newQuantity) => {
    setQuantity(newQuantity);
  };

  return (
    <div className={classes.root}>
      <Column spacing={2}>
        <Card>
          <CardContent>
            <Typography>
              {
                "Congratulations on selecting a plan! We're happy you're here! Before we can get started, we need to collect some information about you and your company."
              }
            </Typography>
          </CardContent>
        </Card>
        <Row spacing={2}>
          <Card className={classes.contentCard}>
            <CardContent>
              <CustomerForm saving={saving} onSubmit={handleSubmit} />
              <ControlledSnackbar
                state={snackbarState}
                onClose={handleSnackbarClose}
              />
            </CardContent>
          </Card>
          <SubscriptionTierCard
            product={product}
            quantity={quantity}
            buttonDisabled
            onQuantityChanged={handleQuantityChanged}
          />
        </Row>
      </Column>
    </div>
  );
}
