import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  fullWidthRoot: {
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ProgressButton(props) {
  const { children, loading, type, fullWidth, variant, color, onClick } = props;
  const classes = useStyles();

  return (
    <div className={fullWidth ? classes.fullWidthRoot : classes.root}>
      <Button
        type={type}
        fullWidth={fullWidth}
        variant={variant}
        color={color}
        disabled={loading}
        onClick={onClick}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}

ProgressButton.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default ProgressButton;
