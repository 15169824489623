import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorState } from "../../widgets";
import { Card } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(180deg, white, 10%, white, 60%, #004E89)",
    minHeight: "100vh",
    overflowY: "scroll",
    justifyContent: "center",
  },
}));

export default function TestPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Card>
        <ErrorState message="Here is an error message. We're really sorry." />
      </Card>
    </div>
  );
}
