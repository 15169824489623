import React, { useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { activateUser } from "../../data/user-store";

function ActivateUserButton(props) {
  const { userId, onShowSnackbar } = props;
  const [activating, setActivating] = useState(null);

  const handleClick = async () => {
    setActivating(true);

    try {
      await activateUser(userId);

      onShowSnackbar({
        open: true,
        message: "Activated user.",
        severity: "success",
        autoHideDuration: 6000,
      });
    } catch (error) {
      onShowSnackbar({
        open: true,
        message: "Failed to reactivate user",
        severity: "error",
        error: error,
      });
    } finally {
      setActivating(false);
    }
  };

  return (
    <React.Fragment>
      <Tooltip title={"Activate User"} aria-label="activate user">
        {activating ? (
          <CircularProgress />
        ) : (
          <Button aria-label="activate user" onClick={handleClick}>
            ACTIVATE
          </Button>
        )}
      </Tooltip>
    </React.Fragment>
  );
}

ActivateUserButton.propTypes = {
  userId: PropTypes.string,
  email: PropTypes.string,
  onShowSnackbar: PropTypes.func,
};

export default ActivateUserButton;
