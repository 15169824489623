import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { getDatabaseField } from "../util/legal-doc-utils";

export async function getUser(userId) {
  const userDoc = await firebase.firestore().doc(`users/${userId}`).get();

  if (!userDoc) return null;

  return userDoc.data();
}

export async function putUser(userId, formData) {
  const putUserFn = firebase.functions().httpsCallable("putUser");

  const result = await putUserFn({
    userId: userId,
    formData: formData,
  });

  return result.data;
}

export async function signUp(formData, selectedProduct) {
  console.log("calling signUp");
  const signUpFn = firebase.functions().httpsCallable("signUp");

  const result = await signUpFn({
    formData: formData,
    selectedProduct: selectedProduct,
  });

  console.log("got result");
  console.log(JSON.stringify(result));
  return result.data.subscription;
}

export async function inviteUser(user) {
  const inviteUserFn = firebase.functions().httpsCallable("sendInvitation");

  const result = await inviteUserFn({
    user: user,
  });

  return result.data.message;
}

export async function resendInvitation(email) {
  const resendInvitationFn = firebase
    .functions()
    .httpsCallable("resendInvitation");

  const result = await resendInvitationFn({
    email: email,
  });

  return result.data.message;
}

export async function acceptInvitation(token, formData) {
  const acceptInvitationFn = firebase
    .functions()
    .httpsCallable("acceptInvitation");

  const result = await acceptInvitationFn({
    token: token,
    formData: formData,
  });

  return result.data.message;
}

export async function getInvitedUser(token) {
  const getUserFn = firebase.functions().httpsCallable("getInvitedUser");

  const result = await getUserFn({
    token: token,
  });

  return result.data.user;
}

export async function deleteUser(userId) {
  const deleteUserFn = firebase.functions().httpsCallable("deleteUser");

  const result = await deleteUserFn({
    userId: userId,
  });

  return result.data.message;
}

export async function deactivateUser(userId) {
  const disableUserFn = firebase.functions().httpsCallable("disableUser");

  const result = await disableUserFn({
    userId: userId,
  });

  return result.data.message;
}

export async function activateUser(userId) {
  const enableUserFn = firebase.functions().httpsCallable("enableUser");

  const result = await enableUserFn({
    userId: userId,
  });

  return result.data.message;
}

export async function verifyUserEmail(token) {
  const verifyEmailFn = firebase.functions().httpsCallable("verifyEmail");

  const result = await verifyEmailFn({
    token: token,
  });

  return result.data.user;
}

export async function getLegalDocsNeedingAcceptance(userId) {
  try {
    await new Promise((r) => setTimeout(r, 2000));
    const user = await getUser(userId);

    if (!user) return null;

    const globalConfigSnapshot = await firebase
      .firestore()
      .doc(`config/global`)
      .get();
    if (!globalConfigSnapshot) return null;

    const config = globalConfigSnapshot.data();

    var docs = [];

    if (
      user.acceptedLegalDocs?.privacyPolicy > config.privacyPolicyDate !=
      true
    ) {
      docs.push("privacyPolicy");
    }

    if (user.acceptedLegalDocs?.termsOfUse > config.termsOfUseDate != true) {
      docs.push("termsOfUse");
    }

    return docs;
  } catch (e) {
    console.log(e);
  }
}

export async function acceptLegalDoc(userId, docType) {
  const field = getDatabaseField(docType);
  await firebase.firestore().doc(`users/${userId}`).update(field, new Date());
}

export default {
  getUser,
  putUser,
  inviteUser,
  resendInvitation,
  acceptInvitation,
  getInvitedUser,
  deleteUser,
  deactivateUser,
  activateUser,
  getLegalDocsNeedingAcceptance,
  acceptLegalDoc,
};
