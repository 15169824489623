function formatFirebaseError(error) {
  if (error?.name == "FirebaseError") {
    switch (error.code) {
      case "permission-denied":
        return "You do not have permission to access the requested data.";
      default:
        return JSON.stringify(error);
    }
  }

  if (error) {
    return JSON.stringify(error);
  }

  return "Unknown error";
}

export { formatFirebaseError };
