import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexGrow: 1,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  progress: {
    margin: "auto",
  },
  empty: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    padding: theme.spacing(2),
  },
}));

function GridCard(props) {
  const { title, empty, loading, error } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={5}>
      {title && (
        <Typography
          className={classes.title}
          component="h2"
          variant="h6"
          gutterBottom
        >
          {title}
        </Typography>
      )}
      {!loading && props.children}
      {empty && (
        <Typography className={classes.empty} component="h3">
          No items to display
        </Typography>
      )}
      {loading && <CircularProgress className={classes.progress} />}
      {error && (
        <Typography className={classes.error} component="h3">
          {error}
        </Typography>
      )}
    </Paper>
  );
}

GridCard.propTypes = {
  title: PropTypes.string,
  empty: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.node,
};

export default GridCard;
