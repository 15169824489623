import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GridCard from "../../widgets/GridCard";
import { Column } from "../../widgets";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

function ReportSettingsCard(props) {
  const { organization, loading, canEdit, onOrganizationChanged } = props;
  const classes = useStyles();

  const handleReportTypeChange = (event) => {
    const updatedOrg = {};
    Object.assign(updatedOrg, organization);

    if (!updatedOrg.reportSettings) {
      updatedOrg.reportSettings = {};
    }

    const name = event.target.name;

    const reportArray = organization?.reportSettings?.supportedFormats ?? [];
    if (event.target.checked) {
      if (!reportArray.includes(name)) {
        updatedOrg.reportSettings.supportedFormats = [...reportArray, name];
      }
    } else {
      updatedOrg.reportSettings.supportedFormats = reportArray.filter(
        (i) => i != name
      );
    }

    onOrganizationChanged(updatedOrg);
  };

  const handleDecimalPlacesChange = (event) => {
    const updatedOrg = {};
    Object.assign(updatedOrg, organization);

    if (!updatedOrg.reportSettings) {
      updatedOrg.reportSettings = {};
    }

    updatedOrg.reportSettings.decimalPlaces = event.target.value;

    onOrganizationChanged(updatedOrg);
  };

  const controlsDisabled = !canEdit;

  const xlsxEnabled =
    organization?.reportSettings?.supportedFormats?.includes("xlsx") ?? false;
  const pdfEnabled =
    organization?.reportSettings?.supportedFormats?.includes("pdf") ?? false;

  return (
    <GridCard title="Report Settings" loading={loading}>
      <Column padding={2}>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="report-decimals-label">
            Report Decimal Places
          </InputLabel>
          <Select
            labelId="report-decimals-label"
            id="reportDecimals"
            value={organization?.reportSettings?.decimalPlaces ?? 1}
            onChange={handleDecimalPlacesChange}
            disabled={controlsDisabled}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          component="fieldset"
          fullWidth
          className={classes.formControl}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name="xlsx"
                  checked={xlsxEnabled}
                  onChange={handleReportTypeChange}
                  disabled={controlsDisabled}
                />
              }
              label="Enable XLSX Report"
            />
            <FormControlLabel
              control={
                <Switch
                  name="pdf"
                  checked={pdfEnabled}
                  onChange={handleReportTypeChange}
                  disabled={controlsDisabled}
                />
              }
              label="Enable PDF Report"
            />
          </FormGroup>
        </FormControl>
      </Column>
    </GridCard>
  );
}

ReportSettingsCard.propTypes = {
  organization: PropTypes.object,
  loading: PropTypes.bool,
  canEdit: PropTypes.bool,
  onOrganizationChanged: PropTypes.func,
};

export default ReportSettingsCard;
