import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  navBar: {
    backgroundColor: "transparent",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const NavBar = function NavBar(props) {
  const { ...other } = props;
  const classes = useStyles();

  return (
    <Paper
      square
      component="header"
      elevation={0}
      className={classes.navBar}
      {...other}
    />
  );
};

NavBar.propTypes = {};

export default NavBar;
