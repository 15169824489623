import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import { Column } from "../../widgets";

const useStyles = makeStyles((theme) => ({
  grid: {
    flex: "1 1 auto",
    margin: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 250,
  },
];

function mapSnapshotToRows(snapshot) {
  if (!snapshot) return [];

  const rows = snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      name: data.name,
    };
  });

  return rows;
}

function OrganizationListExpanded(props) {
  const { loading, snapshot, error, onItemSelected } = props;
  const classes = useStyles();

  useEffect(() => {
    document.title = "Organizations - Runyon Data Services";
  });

  const handleSelectionModelChange = (selectionModel) => {
    onItemSelected(selectionModel.length > 0 ? selectionModel[0] : undefined);
  };

  const handleCellClick = (params, event) => {
    if (params.colDef.field == "deactivate") {
      event.stopPropagation();
    }
  };

  const rows = mapSnapshotToRows(snapshot);

  return (
    <React.Fragment>
      {rows.length == 0 && !loading && (
        <Column spacing={2} horizontalArrangement="centerHorizontally">
          <img src="images/NoDocuments@2x.png" />
          <Typography>There are no organizations to display</Typography>
        </Column>
      )}
      {(loading || rows.length > 0) && (
        <DataGrid
          className={classes.grid}
          rows={rows}
          columns={columns}
          loading={loading}
          error={error}
          onSelectionModelChange={handleSelectionModelChange}
          onCellClick={handleCellClick}
        />
      )}
    </React.Fragment>
  );
}

OrganizationListExpanded.propTypes = {
  loading: PropTypes.bool,
  snapshot: PropTypes.object,
  error: PropTypes.object,
  onItemSelected: PropTypes.func,
};

export default OrganizationListExpanded;
