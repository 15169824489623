import React, { useState } from "react";
import { Switch, useHistory } from "react-router";
import { useAuth } from "../../hooks/use-auth";
import PrivateRoute from "../../widgets/PrivateRoute.jsx";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { formatFirebaseError } from "../../data/error-formatter.js";
import { useCollection } from "react-firebase-hooks/firestore";
import { makeStyles } from "@material-ui/core/styles";
import { OrganizationSelector } from "../../widgets";
import InspectionListCollapsed from "./InspectionListCollapsed";
import InspectionListExpanded from "./InspectionListExpanded";
import { NavBar } from "../../widgets";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ControlledSnackbar from "../../widgets/ControlledSnackbar";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
  },
  buttonRow: {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "row",
  },
  grid: {
    flex: "1 1 auto",
  },
  create: {
    flex: "0 1 auto",
    marginBottom: theme.spacing(1),
  },
  spacer: {
    flex: "1 1 auto",
  },
  orgSelect: {
    margin: theme.spacing(2),
    width: 200,
  },
  orgSelectCollapsed: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
}));

function createQuery(organization, auth) {
  const query = firebase
    .firestore()
    .collection("inspections")
    .where("organization", "==", organization.id);

  if (auth.user.claims.isAdmin || auth.user.claims.isSuperAdmin) {
    return query.orderBy("createdAt", "desc");
  } else {
    console.log(`User ${auth.authUser.uid} is not admin, adding author param`);
    return query
      .where("author", "==", auth.authUser.uid)
      .orderBy("createdAt", "desc");
  }
}

function InspectionList() {
  const classes = useStyles();
  const [auth] = useAuth();
  const history = useHistory();

  const [snackbarState, setSnackbarState] = useState({});
  const [organization, setOrganization] = useState({
    id: auth.user.claims.organization,
    name: auth.user.claims.organization,
  });
  const [snapshot, loading, error] = useCollection(
    createQuery(organization, auth)
  );

  const getError = () => {
    if (error === undefined) return undefined;

    console.log("Formatting error");
    return { message: formatFirebaseError(error) };
  };

  const handleOrganizationChange = (organization) => {
    setOrganization(organization);
  };

  const handleShowSnackbar = (snackbarState) => {
    setSnackbarState(snackbarState);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleItemSelected = (itemId) => {
    if (!itemId) {
      history.push("/inspections");
    } else {
      history.push(`/inspections/${itemId}`);
    }
  };

  return (
    <React.Fragment>
      <NavBar>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Inspections
          </Typography>
        </Toolbar>
      </NavBar>
      {auth.user.claims.isSuperAdmin && (
        <OrganizationSelector
          className={classes.orgSelect}
          organization={organization}
          onChange={handleOrganizationChange}
          size="small"
          variant="outlined"
        />
      )}
      <Switch>
        <PrivateRoute path="/inspections/:selectedId">
          <InspectionListCollapsed
            snapshot={snapshot}
            onItemSelected={handleItemSelected}
          />
        </PrivateRoute>
        <PrivateRoute path="/inspections">
          <InspectionListExpanded
            organizationId={organization.id}
            loading={loading}
            snapshot={snapshot}
            error={getError()}
            onItemSelected={handleItemSelected}
            onShowSnackbar={handleShowSnackbar}
          />
        </PrivateRoute>
      </Switch>

      <ControlledSnackbar state={snackbarState} onClose={handleSnackbarClose} />
    </React.Fragment>
  );
}

InspectionList.propTypes = {};

export default InspectionList;
