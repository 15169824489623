import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./hooks/use-auth.js";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      light: "#57a9ec",
      main: "#007ab9",
      dark: "#004e89",
      contrastText: "#ffffff",
    },
    secondary: {
      veryLight: "#e5f6ff",
      light: "#b3e5ff",
      main: "#1ab1ff",
      dark: "#004e89",
      contrastText: "#000000",
    },
    error: {
      main: "#d41111",
    },
    sucess: {
      main: "#ff73c322",
    },
    warning: {
      main: "#ffab1a",
    },
    gray: {
      veryVeryLight: "#f8f8f8",
      veryLight: "#f5f5f5",
      light: "#f2f2f3",
      main: "#c8cdd0",
      dark: "#415058",
    },
    black: {
      main: "1f292e",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
