import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Column } from "../../widgets";
import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles(() => ({
  root: {
    width: "40%",
    minWidth: 200,
    maxWidth: 400,
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export default function AwaitingEmailVerification() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Column horizontalAlignment="centerHorizontally">
          <CheckCircleIcon style={{ color: green[500], fontSize: 100 }} />
          <Typography>Your account has been created!</Typography>
          <br />
          <Typography>
            Before we can finish setting up your account, we need to verify your
            email address. Check your email and follow the instructions in the
            message we sent you.
          </Typography>
        </Column>
      </CardContent>
    </Card>
  );
}
