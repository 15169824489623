import { useState, useEffect } from "react";
import resolveConfig from "../util/resolve-config";
import { loadStripe } from "@stripe/stripe-js";

export function useStripeInstance() {
  const [stripe, setStripe] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(true);

    const config = resolveConfig();
    const stripe = await loadStripe(config.stripe.apiKey);

    console.log("stripe loaded");
    setStripe(stripe);
    setLoading(false);
  }, []);

  return [stripe, loading];
}

export function usePaymentIntent(clientSecret) {
  const [paymentIntent, setPaymentIntent] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stripe, loadingStripe] = useStripeInstance();

  useEffect(async () => {
    setLoading(true);

    if (!clientSecret) {
      setLoading(false);
      return;
    }

    if (loadingStripe) {
      return;
    }

    const result = await stripe.retrievePaymentIntent(clientSecret);

    if (result.error) {
      setError(error);
    } else {
      setPaymentIntent(result.paymentIntent);
    }

    setLoading(false);
  }, [loadingStripe, clientSecret]);

  return [paymentIntent, loading, error];
}
