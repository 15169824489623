import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GridCard from "../../widgets/GridCard";
import { Column } from "../../widgets";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

function FeaturesCard(props) {
  const { organization, loading, canEdit, onOrganizationChanged } = props;
  const classes = useStyles();

  const handleFeatureChange = (event) => {
    const updatedOrg = {};
    Object.assign(updatedOrg, organization);

    const name = event.target.name;

    updatedOrg.features = {
      ...updatedOrg.features,
      [name]: event.target.checked,
    };

    onOrganizationChanged(updatedOrg);
  };

  const controlsDisabled = !canEdit;

  return (
    <GridCard title="Platform Features" loading={loading}>
      <Column padding={2}>
        <FormControl
          component="fieldset"
          fullWidth
          className={classes.formControl}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name="enablePhotos"
                  checked={organization?.features?.enablePhotos ?? true}
                  onChange={handleFeatureChange}
                  disabled={controlsDisabled}
                />
              }
              label="Enable Photos"
            />
            <FormControlLabel
              control={
                <Switch
                  name="enableSignatures"
                  checked={organization?.features?.enableSignatures ?? true}
                  onChange={handleFeatureChange}
                  disabled={controlsDisabled}
                />
              }
              label="Enable Signatures"
            />
          </FormGroup>
        </FormControl>
      </Column>
    </GridCard>
  );
}

FeaturesCard.propTypes = {
  organization: PropTypes.object,
  loading: PropTypes.bool,
  canEdit: PropTypes.bool,
  onOrganizationChanged: PropTypes.func,
};

export default FeaturesCard;
