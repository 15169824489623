import { React, useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import PrivateRoute from "./widgets/PrivateRoute.jsx";
import { useAuth } from "./hooks/use-auth.js";
import { SignIn, SignUp, AcceptInvite, TestPage } from "./pages";
import MainInterface from "./main-interface";

function App() {
  const [auth] = useAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = "Runyon Data Services";
  }, []);

  useEffect(() => {
    const { from } = location.state || { from: { pathname: "/" } };

    if (
      auth.user &&
      auth.status == "signedIn" &&
      location.pathname == "/sign-in"
    ) {
      history.replace(from);
    }
  });

  const reload = () => window.location.reload();

  return (
    <div className="App">
      {auth.status != "initializing" && (
        <Switch>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/accept-invite">
            <AcceptInvite />
          </Route>
          <Route path="/test-page">
            <TestPage />
          </Route>
          <Route path="/privacy-policy.html" onEnter={reload} />
          <Route path="/terms-of-use.html" onEnter={reload} />
          <Route path="/privacy-policy.md" onEnter={reload} />
          <Route path="/terms-of-use.md" onEnter={reload} />
          <Route path="/global-config.json" onEnter={reload} />
          <PrivateRoute path="/">
            <MainInterface />
          </PrivateRoute>
        </Switch>
      )}
    </div>
  );
}

export default App;
