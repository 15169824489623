/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  listenToRequestStatus,
  getDownloadURL,
  createReportRequest,
} from "../../data/report-store";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const makeTooltipLabel = (status) => {
  switch (status) {
    case "idle":
      return "Download Report";
    case "requesting":
      return "Requesting report...";
    case "requested":
      return "Waiting to build report...";
    case "building":
      return "Building report...";
    case "done":
      return "Download Report";
    case "error":
      return "Download Report";
  }
};

const requestReportAndWatch = (
  inspectionId,
  reportType,
  handleStatusChange,
  handleError
) => {
  // Do nothing if there's no inspectionId, because
  // that means the window is closed.
  if (!inspectionId || inspectionId == "") {
    return null;
  }

  // Create a place to hold the cleanup function we will get asynchronously.
  const cleanupHandle = {};

  // Wrapper function for async effect
  const generateReport = async () => {
    try {
      // Request the report
      const requestPath = await createReportRequest(inspectionId, reportType);

      // Listen to the status on the report request
      const cleanup = listenToRequestStatus(
        requestPath,
        handleStatusChange,
        handleError
      );

      // Set the real cleanup function on the holder object.
      cleanupHandle.cleanup = cleanup;
    } catch (error) {
      handleError(error);
    }
  };

  // Run async effect
  generateReport();

  // Return a cleanup function that gets the real cleanup function
  // from the holder object and runs it.
  return () => {
    if (cleanupHandle.cleanup) {
      cleanupHandle.cleanup();
    }
  };
};

function ReportDownloadButton(props) {
  const { inspectionId, reportTypes, onReportReady, onError } = props;

  const [building, setBuilding] = useState(null);
  const [status, setStatus] = useState("idle");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (reportTypes.length == 1) {
      setBuilding(reportTypes[0]);
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (reportType) => {
    setBuilding(reportType);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (status, requestPath) => {
    setStatus(status);

    if (status == "done") {
      const downloadUrl = await getDownloadURL(requestPath);
      setBuilding(false);

      onReportReady(downloadUrl);
    }
  };

  const handleError = (error) => {
    setBuilding(false);

    onError(error);
  };

  const tooltipLabel = makeTooltipLabel(status);

  useEffect(() => {
    if (building) {
      setStatus("requesting");
      return requestReportAndWatch(
        inspectionId,
        building,
        handleStatusChange,
        handleError
      );
    }
  }, [building]);

  return (
    <React.Fragment>
      <Tooltip title={tooltipLabel} aria-label="download report">
        {building ? (
          <CircularProgress />
        ) : (
          <IconButton aria-label="download report" onClick={handleClick}>
            <DescriptionIcon />
          </IconButton>
        )}
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("pdf")}>PDF</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("xlsx")}>Excel</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

ReportDownloadButton.propTypes = {
  inspectionId: PropTypes.string,
  reportTypes: PropTypes.arrayOf(PropTypes.string),
  onReportReady: PropTypes.func,
  onError: PropTypes.func,
};

export default ReportDownloadButton;
