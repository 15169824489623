import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import GridCard from "../../widgets/GridCard.jsx";

const useStyles = makeStyles((theme) => ({
  selectedItem: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.action.selected,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

function SampleList(props) {
  const { snapshot, loading, error, selectedSampleId, onSampleSelected } =
    props;
  const classes = useStyles();

  const empty = snapshot && snapshot.docs.length == 0;

  const handleRowClick = (event) => {
    const sampleId = event.currentTarget.getAttribute("sampleid");

    if (onSampleSelected) {
      onSampleSelected(sampleId);
    }
  };

  const renderRow = (doc) => {
    const data = doc.data();
    const selected = doc.id == selectedSampleId;

    return (
      <TableRow
        key={doc.id}
        sampleid={doc.id}
        hover
        selected={selected}
        onClick={handleRowClick}
        classes={{
          selected: classes.selectedItem,
        }}
      >
        <TableCell>{data.number}</TableCell>
        <TableCell>{data.gloss60}</TableCell>
        <TableCell>{data.gloss85}</TableCell>
        <TableCell>{data.haze}</TableCell>
        <TableCell>{data.doi}</TableCell>
        <TableCell>{data.ra}</TableCell>
      </TableRow>
    );
  };

  return (
    <GridCard
      title="Samples"
      empty={empty}
      loading={loading}
      error={error?.message}
    >
      <Table stickyHeader aria-label="samples">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>60°</TableCell>
            <TableCell>85°</TableCell>
            <TableCell>Haze</TableCell>
            <TableCell>DOI</TableCell>
            <TableCell>Ra</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{snapshot && snapshot.docs.map(renderRow)}</TableBody>
      </Table>
    </GridCard>
  );
}

SampleList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  snapshot: PropTypes.object,
  selectedSampleId: PropTypes.string,
  onSampleSelected: PropTypes.func,
};

export default SampleList;
