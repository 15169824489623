import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Column from "../../widgets/Column";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 1 auto",
  },
  listItemTitle: {
    fontWeight: 600,
    marginBottom: 8,
    marginTop: 0,
  },
  listItemContent: {
    color: theme.palette.gray.dark,
    marginTop: 0,
    marginBottom: 0,
  },
}));

function OrganizationListCollapsed(props) {
  const { snapshot, onItemSelected } = props;
  const { selectedId } = useParams();

  const classes = useStyles();

  const renderRow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { index, style } = props;

    const doc = snapshot.docs[index];
    const data = doc.data();

    const selected = doc.id == selectedId;

    return (
      <ListItem
        button
        style={style}
        key={doc.id}
        selected={selected}
        onClick={() => onItemSelected(doc.id)}
        divider
      >
        <Column>
          <p className={classes.listItemTitle}>{data.name}</p>
          <p className={classes.listItemContent}>
            {`${data.address?.city ?? ""}, ${
              data.address?.stateProvince ?? ""
            }`}
          </p>
        </Column>
      </ListItem>
    );
  };

  return (
    <Box className={classes.root}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height}
            width={width}
            itemCount={snapshot?.docs.length ?? 0}
            itemSize={80}
          >
            {renderRow}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  );
}

OrganizationListCollapsed.propTypes = {
  snapshot: PropTypes.object,
  selectedId: PropTypes.string,
  onItemSelected: PropTypes.func,
};

export default OrganizationListCollapsed;
