/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemote";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Divider from "@material-ui/core/Divider";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/use-auth.js";

function AdminSection(props) {
  const { onClick } = props;
  const [auth] = useAuth();

  if (!auth.user.claims.isAdmin) return null;

  return (
    <React.Fragment>
      <Divider />
      <List>
        <ListSubheader inset>Admin</ListSubheader>
        {auth.user.claims.isSuperAdmin && (
          <ListItem
            button
            selected={location.pathname.startsWith("/organizations")}
            onClick={() => onClick("/organizations")}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Organizations" />
          </ListItem>
        )}
        <ListItem
          button
          selected={location.pathname.startsWith("/users")}
          onClick={() => onClick("/users")}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem
          button
          selected={location.pathname.startsWith("/devices")}
          onClick={() => onClick("/devices")}
        >
          <ListItemIcon>
            <SettingsRemoteIcon />
          </ListItemIcon>
          <ListItemText primary="Devices" />
        </ListItem>
      </List>
    </React.Fragment>
  );
}

AdminSection.propTypes = {
  onClick: PropTypes.func,
};

function NavMenu() {
  const history = useHistory();
  const location = useLocation();

  const handleClick = function (newPath) {
    history.push(newPath);
  };

  return (
    <React.Fragment>
      <List>
        {/* <ListItem
          button
          selected={location.pathname == "/"}
          onClick={() => handleClick("/")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem> */}
        <ListItem
          button
          selected={location.pathname.startsWith("/inspections")}
          onClick={() => handleClick("/inspections")}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Inspections" />
        </ListItem>
      </List>
      <AdminSection onClick={handleClick} />
    </React.Fragment>
  );
}

export default NavMenu;
