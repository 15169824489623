import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { NavBar } from "../../widgets";
import { useHistory } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Column } from "../../widgets";
import "firebase/compat/firestore";
import { useAuth } from "../../hooks/use-auth.js";
import InfoCard from "./InfoCard";
import ControlledSnackbar from "../../widgets/ControlledSnackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseButton from "@material-ui/icons/Close";
import { EditOrganizationForm } from ".";
import FeaturesCard from "./FeaturesCard";
import ReportSettingsCard from "./ReportSettingsCard";
import { useOrganization } from "../../hooks/organization-hooks";
import { putOrganization } from "../../data/organization-store";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    background: theme.palette.gray.verVeryLight,
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

function OrganizationDetail(props) {
  const { organizationId, showAsSettings } = props;
  const classes = useStyles();
  const history = useHistory();
  const [auth] = useAuth();

  const [snackbarState, setSnackbarState] = useState({});
  const [organization, loading, error] = useOrganization(organizationId);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const titleText = organization?.name ?? "Loading";
    document.title = `${titleText} - Runyon Data Services`;
  }, [organizationId, organization?.name]);

  const handleClose = () => {
    history.push("/organizations");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleFormSave = async (snackbarState) => {
    setSnackbarState(snackbarState);
    setEditing(false);
  };

  const handleFormCancel = () => {
    setEditing(false);
  };

  const handleOrganizationChanged = (organization) => {
    putOrganization(organization, organizationId);
  };

  const canEdit = auth.user.claims.isAdmin || auth.user.claims.isSuperAdmin;

  return (
    <React.Fragment>
      <NavBar>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {showAsSettings && "Settings - "}
            {organization && organization.name}
            {error && error.message}
          </Typography>
          {!showAsSettings && (
            <IconButton
              color="inherit"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseButton />
            </IconButton>
          )}
        </Toolbar>
      </NavBar>

      {/* This div needs to be here to prevent a horizontal scroll from appearing! */}
      <div className={classes.gridContainer}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <InfoCard
              organization={organization}
              loading={loading}
              canEdit={canEdit}
              onEditClick={handleEditClick}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Column spacing={2}>
              <FeaturesCard
                organization={organization}
                loading={loading}
                canEdit={canEdit}
                onOrganizationChanged={handleOrganizationChanged}
              />

              <ReportSettingsCard
                organization={organization}
                loading={loading}
                canEdit={canEdit}
                onOrganizationChanged={handleOrganizationChanged}
              />
            </Column>
          </Grid>
        </Grid>
      </div>

      {editing && (
        <EditOrganizationForm
          organizationId={organizationId}
          onCancel={handleFormCancel}
          onSave={handleFormSave}
        />
      )}

      <ControlledSnackbar state={snackbarState} onClose={handleSnackbarClose} />
    </React.Fragment>
  );
}

OrganizationDetail.propTypes = {
  organizationId: PropTypes.string,
  showAsSettings: PropTypes.bool,
};

export default OrganizationDetail;
