import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useSubscription } from "../../hooks/organization-hooks";
import AsyncContent from "../../widgets/AsyncContent";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
  },
  content: {
    marginLeft: theme.spacing(2),
  },
}));

function SubscriptionSeatsWidget({ organizationId }) {
  const classes = useStyles();
  const history = useHistory();
  const [subscription, loading, error] = useSubscription(organizationId);

  const handleUpgradeClicked = () => {
    history.push("/subscription");
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6">Seats</Typography>
        <AsyncContent loading={loading} error={error}>
          <div className={classes.content}>
            <Typography>{subscription.usedSeats} used</Typography>
            <Typography>{subscription.availableSeats} available</Typography>
          </div>
        </AsyncContent>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={handleUpgradeClicked}>
          Upgrade
        </Button>
      </CardActions>
    </Card>
  );
}

SubscriptionSeatsWidget.propTypes = {
  organizationId: PropTypes.string,
};

export default SubscriptionSeatsWidget;
