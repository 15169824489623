import { useJsApiLoader } from "@react-google-maps/api";
import resolveConfig from "../util/resolve-config";

export const useGoogleMaps = () => {
  const config = resolveConfig();

  return useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.firebase.apiKey,
    libraries: config.firebase.libraries,
  });
};
