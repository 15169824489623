/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { formatFirebaseError } from "../data/error-formatter";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

function createOrganizationQuery() {
  return firebase.firestore().collection("organizations");
}

function mapOrganizationSnapshotToOptions(snapshot) {
  if (!snapshot) return [];

  return snapshot.docs.map((doc) => {
    const data = doc.data();
    return (
      <option key={doc.id} value={doc.id}>
        {data.name}
      </option>
    );
  });
}

function getOrganizationOptions(snapshot, loading, loadingError) {
  if (loading || loadingError) return [];

  let options = [];

  options.push(<option key="placeholder" value="" orgid="" disabled></option>);

  return options.concat(mapOrganizationSnapshotToOptions(snapshot));
}

function OrganizationSelector(props) {
  const {
    className,
    size,
    organization,
    variant,
    required,
    error,
    onChange,
    fullWidth,
  } = props;

  const [snapshot, loading, loadingError] = useCollectionOnce(
    createOrganizationQuery()
  );

  const orgOptions = getOrganizationOptions(snapshot, loading, loadingError);

  const hasError = error || loadingError;

  const handleOrganizationChange = (event) => {
    const orgId = event.target.value;
    const orgName = event.target[event.target.selectedIndex].text;

    const newOrg = {
      id: orgId,
      name: orgName,
    };

    onChange(newOrg);
  };

  const getErrorMessage = () => {
    if (error) return error;

    if (loadingError) return formatFirebaseError(loadingError);
  };
  return (
    <FormControl
      variant={variant}
      className={className}
      required={required}
      error={hasError}
      size={size}
      fullWidth={fullWidth}
    >
      <InputLabel id="organization-label">Organization</InputLabel>
      {snapshot && (
        <Select
          labelId="organization-label"
          id="organization"
          native
          value={organization.id}
          onChange={handleOrganizationChange}
          label="Organization"
          displayEmpty
          autoFocus
        >
          {orgOptions}
        </Select>
      )}
      {hasError && <FormHelperText>{getErrorMessage()}</FormHelperText>}
    </FormControl>
  );
}

OrganizationSelector.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  error: PropTypes.any,
  onChange: PropTypes.func,
};

export default OrganizationSelector;
