import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const mapAlignmentToAlign = (horizontalAlignment) => {
  switch (horizontalAlignment) {
    case "start":
      return "flex-start";
    case "end":
      return "flex-end";
    case "centerHorizontally":
      return "center";
    default:
      return "flex-start";
  }
};

const mapArrangementToJustify = (verticalArrangement) => {
  switch (verticalArrangement) {
    case "top":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "center":
      return "center";
    case "spaceEvenly":
      return "space-evenly";
    case "spaceAround":
      return "space-around";
    case "spaceBetween":
      return "space-between";
    default:
      return "flex-start";
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: (props) => props.weight ?? 0,
    alignItems: (props) => mapAlignmentToAlign(props.horizontalAlignment),
    justifyContent: (props) =>
      mapArrangementToJustify(props.verticalArrangement),
    gap: (props) => (props.spacing ? theme.spacing(props.spacing) : 0),
    margin: (props) => (props.padding ? theme.spacing(props.padding) : 0),
  },
}));

function Column(props) {
  const classes = useStyles(props);

  return <div className={classes.root}>{props.children}</div>;
}

Column.propTypes = {
  padding: PropTypes.number,
  weight: PropTypes.number,
  spacing: PropTypes.number,
  horizontalAlignment: PropTypes.oneOf(["start", "end", "centerHorizontally"]),
  verticalArrangement: PropTypes.oneOf([
    "top",
    "bottom",
    "center",
    "spaceEvenly",
    "spaceAround",
    "spaceBetween",
  ]),
  children: PropTypes.node,
};

export default Column;
