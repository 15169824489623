import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import UserWidget from "./UserWidget";
import Button from "@material-ui/core/Button";
import resolveConfig from "../util/resolve-config";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    maxWidth: 250,
  },
  tierDescription: {
    height: theme.spacing(10),
    marginTop: theme.spacing(1),
  },
  tierPrice: {
    height: theme.spacing(10),
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  button: {
    marginTop: theme.spacing(),
    width: "100%",
  },
  tierName: {
    fontWeight: "bold",
  },
  priceText: {
    fontWeight: "bold",
  },
  customPricingText: {
    maxWidth: 100,
    fontSize: "28px",
    fontWeight: "bold",
    lineHeight: "34px",
  },
}));

const getCardConfiguration = (
  product,
  quantity,
  disabled,
  buttonText,
  classes
) => {
  const { subscriptionTiers } = resolveConfig();

  switch (product) {
    case subscriptionTiers.individual.priceId:
      // Individual
      return {
        title: "Individual",
        description:
          "For individual contractors / technicians who want to provide proof of quality work.",
        priceText: "$250",
        priceTextProps: {
          className: classes.priceText,
          variant: "h3",
        },
        priceSubtext: "per month",
        userWidget: {
          minUsers: 1,
          maxUsers: 1,
          stepSize: 1,
          disabled: true,
          value: quantity,
        },
        buttonText: buttonText ?? "Sign Up",
      };

    case subscriptionTiers.team.priceId:
      // Team
      return {
        title: "Team",
        description: "For small and medium sized teams.",
        priceText: `$${quantity * 500}`,
        priceTextProps: {
          className: classes.priceText,
          variant: "h3",
        },
        priceSubtext: "per month",
        userWidget: {
          minUsers: 5,
          maxUsers: 20,
          stepSize: 5,
          value: quantity * 5,
          disabled: disabled,
        },
        buttonText: buttonText ?? "Sign Up",
      };
    case subscriptionTiers.enterprise.priceId:
      // Enterprise
      return {
        title: "Enterprise",
        description: "For medium and large organizations with advanced needs.",
        priceText: "Custom pricing",
        priceTextProps: {
          className: classes.customPricingText,
        },
        userWidget: {
          minUsers: 20,
          maxUsers: Number.POSITIVE_INFINITY,
          value: quantity,
          disabled: true,
        },
        buttonText: buttonText ?? "Contact Us",
      };
    default:
      return {
        title: "",
        description: "",
        priceText: "",
        userWidget: {
          minUsers: 1,
          maxUsers: 1,
          value: quantity,
          disabled: true,
        },
        buttonText: buttonText,
      };
  }
};

function SubscriptionTierCard({
  product,
  quantity,
  disabled,
  buttonDisabled,
  buttonText,
  onSelected,
  onQuantityChanged,
}) {
  const classes = useStyles();

  const config = getCardConfiguration(
    product,
    quantity,
    disabled,
    buttonText,
    classes
  );

  const handleUserWidgetValueChanged = (newValue) => {
    const newQuantity = newValue / config.userWidget.stepSize;

    if (onQuantityChanged) {
      onQuantityChanged(newQuantity);
    }
  };

  const handleClick = () => {
    onSelected(product, quantity);
  };

  return (
    <Card className={classes.root} elevation={5}>
      <CardContent>
        <Typography variant="h5" className={classes.tierName}>
          {config.title}
        </Typography>
        <div className={classes.tierDescription}>
          <Typography variant="body2">{config.description}</Typography>
        </div>
        <div className={classes.tierPrice}>
          <Typography {...config.priceTextProps}>{config.priceText}</Typography>
          <Typography>{config.priceSubtext}</Typography>
        </div>
        <UserWidget
          {...config.userWidget}
          setValue={handleUserWidgetValueChanged}
        />

        {!buttonDisabled && (
          <Button
            onClick={handleClick}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {config.buttonText}
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

SubscriptionTierCard.propTypes = {
  product: PropTypes.string,
  quantity: PropTypes.number,
  disabled: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  buttonText: PropTypes.string,
  onSelected: PropTypes.func,
  onQuantityChanged: PropTypes.func,
};

export default SubscriptionTierCard;
