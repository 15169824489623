import React, { useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import DeleteIcon from "@material-ui/icons/Delete";
import { deactivateUser, deleteUser } from "../../data/user-store";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

function DeactivateUserButton(props) {
  const { userId, email, enableHardDelete, onShowSnackbar } = props;

  const [dialogState, setDialogState] = useState({ open: false });
  const [deleting, setDeleting] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDisableUser = async () => {
    handleMenuClose();
    setDeleting(true);

    try {
      await deactivateUser(userId);

      onShowSnackbar({
        open: true,
        message: "User disabled.",
        severity: "success",
        autoHideDuration: 6000,
      });
    } catch (error) {
      onShowSnackbar({
        open: true,
        message: "Disable user failed.",
        severity: "error",
        error: error,
      });
    } finally {
      setDeleting(false);
    }
  };

  const handleDeleteUser = async () => {
    console.log(`Deleting user ${userId}`);
    setDeleting(true);
    setDialogState({
      ...dialogState,
      ["open"]: false,
    });

    try {
      await deleteUser(userId);

      onShowSnackbar({
        open: true,
        message: "User deleted.",
        severity: "success",
        autoHideDuration: 6000,
      });
    } catch (error) {
      onShowSnackbar({
        open: true,
        message: "User deletion failed.",
        severity: "error",
        error: error,
      });
    } finally {
      setDeleting(false);
    }
  };

  const confirmDeleteUser = () => {
    handleMenuClose();

    setDialogState({
      ...dialogState,
      ["open"]: true,
    });
  };

  const handleClick = async (event) => {
    // If we're allowed to hard delete, then present a menu
    // so the user can pick
    if (enableHardDelete) {
      setAnchorEl(event.currentTarget);
    } else {
      // Otherwise we default to disabling
      handleDisableUser();
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmationClose = () => {
    setDialogState({
      ...dialogState,
      ["open"]: false,
    });
  };

  return (
    <React.Fragment>
      <Tooltip title={"Deactivate"} aria-label="deactivate user">
        {deleting ? (
          <CircularProgress />
        ) : (
          <IconButton aria-label="deactivate user" onClick={handleClick}>
            <DeleteIcon />
          </IconButton>
        )}
      </Tooltip>

      {/* Dropdown menu for selecting between delete /disable */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDisableUser}>Deactivate</MenuItem>
        <MenuItem onClick={confirmDeleteUser}>Delete</MenuItem>
      </Menu>

      {/* Confirmation dialog for warning about user deletes */}
      <Dialog open={dialogState.open} onClose={handleConfirmationClose}>
        <DialogTitle id="confirm-dialog-title">Delete this user?</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            If you proceed, the user {email} will be permanently deleted!
            Content they have previously created may no longer work. If you are
            unsure, disable the user instead.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmationClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

DeactivateUserButton.propTypes = {
  userId: PropTypes.string,
  email: PropTypes.string,
  enableHardDelete: PropTypes.bool,
  onShowSnackbar: PropTypes.func,
};

export default DeactivateUserButton;
