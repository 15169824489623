import passwordValidator from "password-validator";
import addrs from "email-addresses";

export function isPasswordValid(password) {
  let schema = new passwordValidator();

  schema
    .is()
    .min(8)
    .is()
    .max(100)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .not()
    .spaces()
    .has()
    .symbols(1)
    .is()
    .not()
    .oneOf(["Passw0rd", "Password123"]);

  return schema.validate(password);
}

export function isEmailValid(email) {
  return addrs.parseOneAddress(email);
}
