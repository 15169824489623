import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const mapAlignmentToAlign = (verticalAlignment) => {
  switch (verticalAlignment) {
    case "top":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "centerVertically":
      return "center";
    default:
      return "flex-start";
  }
};

const mapArrangementToJustify = (horizontalArrangement) => {
  switch (horizontalArrangement) {
    case "start":
      return "flex-start";
    case "end":
      return "flex-end";
    case "center":
      return "center";
    case "spaceEvenly":
      return "space-evenly";
    case "spaceAround":
      return "space-around";
    case "spaceBetween":
      return "space-between";
    default:
      return "flex-start";
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flexGrow: (props) => props.weight ?? 0,
    alignItems: (props) => mapAlignmentToAlign(props.verticalAlignment),
    justifyContent: (props) =>
      mapArrangementToJustify(props.horizontalArrangement),
    gap: (props) => (props.spacing ? theme.spacing(props.spacing) : 0),
    margin: (props) => (props.padding ? theme.spacing(props.padding) : 0),
  },
}));

function Row(props) {
  const classes = useStyles(props);

  return <div className={classes.root}>{props.children}</div>;
}

Row.propTypes = {
  padding: PropTypes.number,
  weight: PropTypes.number,
  spacing: PropTypes.number,
  horizontalArrangement: PropTypes.oneOf([
    "start",
    "end",
    "center",
    "spaceEvenly",
    "spaceAround",
    "spaceBetween",
  ]),
  verticalAlignment: PropTypes.oneOf(["top", "bottom", "centerVertically"]),
  children: PropTypes.node,
};

export default Row;
