import React, { useState } from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";

function InviteUserButton(props) {
  const { enableHardCreate, onInviteUserClick, onCreateUserClick } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (enableHardCreate) {
      setAnchorEl(event.currentTarget);
    } else {
      onInviteUserClick();
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleInviteClick = () => {
    setAnchorEl(null);

    onInviteUserClick();
  };

  const handleCreateClick = () => {
    setAnchorEl(null);

    onCreateUserClick();
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
        endIcon={enableHardCreate && <ExpandMoreIcon />}
      >
        Invite User
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleInviteClick}>Invite</MenuItem>
        <MenuItem onClick={handleCreateClick}>Create</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

InviteUserButton.propTypes = {
  enableHardCreate: PropTypes.bool,
  onInviteUserClick: PropTypes.func,
  onCreateUserClick: PropTypes.func,
};

export default InviteUserButton;
